import React from "react";
import { ReadLinkExternal } from "../components/read-link";
import { css } from "@emotion/core";

import Layout from "../components/layout";

export default () => (
  <Layout>
    <div>
      <p>I'm a London-based Software Engineer from Glasgow.</p>
      <p>
        I have 12+ years of experience building digital products. I'm currently
        working for{" "}
        <ReadLinkExternal
          target="_blank"
          rel="noopener noreferrer"
          href="//www.nearform.com/"
        >
          NearForm.
        </ReadLinkExternal>{" "}
        I've been leading teams and working on web, mobile & Node.js projects
        with{" "}
        <ReadLinkExternal
          target="_blank"
          rel="noopener noreferrer"
          href="//www.virginmedia.com/"
        >
          Virgin Media O2
        </ReadLinkExternal>{" "}
        ,
        <ReadLinkExternal
          target="_blank"
          rel="noopener noreferrer"
          href="///www.ey.com/en_gl/consulting/virtual-internal-auditor"
        >
          EY
        </ReadLinkExternal>
        ,
        <ReadLinkExternal
          target="_blank"
          rel="noopener noreferrer"
          href="//www.nutrien.com/"
        >
          Nutrien
        </ReadLinkExternal>{" "}
        and others.
        <p>
          In the past I've also worked at
          <ReadLinkExternal
            target="_blank"
            rel="noopener noreferrer"
            href="//media.dazn.com/en/"
          >
            {" "}
            DAZN
          </ReadLinkExternal>
          ,
          <ReadLinkExternal
            target="_blank"
            rel="noopener noreferrer"
            href="//github.com/festicket"
          >
            Festicket
          </ReadLinkExternal>
          ,
          <ReadLinkExternal
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.sevenwestmedia.com.au/"
          >
            Seven West Media
          </ReadLinkExternal>
          , and
          <ReadLinkExternal
            target="_blank"
            rel="noopener noreferrer"
            href="//atos.net/en/"
          >
            Atos
          </ReadLinkExternal>
          .
        </p>
      </p>
    </div>
    <p>
      I enjoy using TDD to design and build JavaScript applications &amp;
      systems. I'm a{" "}
      <ReadLinkExternal
        target="_blank"
        rel="noopener noreferrer"
        href="//www.youtube.com/watch?v=8kzu9Mnk9XE"
      >
        keen advocate
      </ReadLinkExternal>{" "}
      for improving performance and accessibility - two often ignored factors
      that can have a dramatic effect on the usability of a product.
    </p>
    <p>You can find me at the usual places.</p>
    <ul
      css={css`
        display: flex;
        list-style: none;
        padding: 0;

        li {
          margin: 0 1em 0 0;
        }
      `}
    >
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="//github.com/richiemccoll"
        >
          <img
            height="32"
            width="32"
            src="https://unpkg.com/simple-icons@latest/icons/github.svg"
            alt="Link to my GitHub profile."
          />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="//linkedin.com/in/richie-mccoll-3b0ab498/"
        >
          <img
            height="32"
            width="32"
            src="https://unpkg.com/simple-icons@latest/icons/linkedin.svg"
            alt="Link to my LinkedIn profile."
          />
        </a>
      </li>
      <li>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="//twitter.com/richiemccoll"
        >
          <img
            height="32"
            width="32"
            src="https://unpkg.com/simple-icons@latest/icons/twitter.svg"
            alt="Link to my Twitter profile."
          />
        </a>
      </li>
    </ul>
  </Layout>
);
