import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";

const NavLink = styled(Link)`
  color: #222;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;

  &.current-page {
    border-bottom: 2px solid #222;
  }
`;

function Avatar() {
  return (
    <div
      css={css`
        background-image: url("/images/rich-22.jpg");
        background-size: cover;
        background-color: #ddd;
        margin: 0 auto;
        height: 100px;
        width: 100px;
        position: relative;
        top: -50px;
        border: 1px solid white;
        border-radius: 50px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.35);
        margin-bottom: -50px;
      `}
    />
  );
}

function Header() {
  return (
    <React.Fragment>
      <header
        css={css`
          height: 100px;
          background-color: #479ad1;
          background-size: cover;
          text-transform: uppercase;
          margin: 0;
        `}
      ></header>
      <Avatar />
      <h1
        css={css`
          text-align: center;
        `}
      >
        Richie McColl
      </h1>
      <nav>
        <ul
          css={css`
            list-style: none;
            text-align: center;
            margin: 2rem;
            padding: 0;

            > * {
              display: inline;
            }
          `}
        >
          <li>
            <NavLink to="/" activeClassName="current-page">
              Writing
            </NavLink>
          </li>
          <li>
            <NavLink to="/about/" activeClassName="current-page">
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/speaking/" activeClassName="current-page">
              Speaking
            </NavLink>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
}

export default Header;
